import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const Wrapper = styled.div`
  ${tw`w-4/5 sm:my-4  shadow-lg relative no-underline rounded-lg px-4 py-8 md:py-8 text-white mx-8 `};
  background: transparent;cursor:pointer;color:#fff;
  border: 1px solid #bbb;opacity:0.8;
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: translateY(-5px);opacity:1;    background: rgba(45, 45, 52, 0.7);
  }
`

const Text = styled.div`
  ${tw`font-Roboto text-sm text-center md:text-sm`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);font-family:roboto;font-weight:300;color:#fff;line-height:20px
`

const Title = styled.div`
  ${tw`text-white text-center tracking-wide pt-1 pb-4 h-16 text-l md:text-xl`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);font-family:miriam libre;font-weight:500;
`
const Im = styled.img`
  ${tw`text-white uppercase text-xl w-auto md:text-xl xl:text-2xl text-center flex mx-auto tracking-wide font-Roboto pt-1 pb-4 h-24`};

`

const ProjectCard = ({ title, link, im, children, bg }) => (
  <Wrapper href={link} target="_blank" rel="noopener noreferrer" bg={bg}>
    <Im src={im} alt="gsap"/>
    <Title>{title}</Title>
    <Text>{children}</Text>
  </Wrapper>
)


export default ProjectCard

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  bg: PropTypes.string.isRequired,
}
