import React from 'react'
import Fade from 'react-reveal/Fade';
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'
// import Typist from 'react-typist'
import TextLoop from "react-text-loop";
import '../../src/styles/decolines.css'
//import ReactContactForm from 'react-mail-form';
// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'
//import Cont from '../components/Con'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import gizimg from '../images/gi.png'
import amsimg from '../images/ams.png'
import serversimg from '../images/servers.png'
import hackimg from '../images/hack.png'
import crmimg from '../images/crm.png'
import backupimg from '../images/backup.png'
import systemimg from '../images/system.png'
import logo from '../images/logo2.png'
import secimg from '../images/sec2.png'
import serverimg from '../images/server.png'
import webimg from '../images/web.png'
import secureimg from '../images/secure.png'
import passimg from '../images/password.png'
import infraimg from '../images/infrastructure.png'

const ProjectsWrapper = styled.div`
  ${tw`flex-wrap justify-between mt-8 flex`};
  display: grid;
  grid-gap: 1rem;clear: both;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 2rem;
  }
  @media (max-width: 900px) {
    display:none;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    display:none;
  }
`

const AboutHero = styled.div`
  ${tw`flex items-center align-center mt-8 w-full `};
`

const Avatar = styled.img`
  ${tw`hidden md:block w-16 xl:w-32  h-auto`};width:125px;
`

const Logo = styled.img`
  ${tw` w-40 h-auto float-none lg:float-left xl:float-left`};clear:both;
`
const LogoDiv = styled.div`
  ${tw`w-auto h-auto float-none lg:float-left xl:float-left`};
`
const MenuDiv = styled.div`
  ${tw`w-full md:text-xs md:p-0 float-left lg:w-3/4 xl:w-3/4 h-auto lg:float-right xl:float-right`};
`
const RedTxt = styled.span`
  ${tw`text-yellow pt-0`};
  display: inline-block;font-size:1rem!important;

`
const RedDot = styled.span`
  ${tw`text-red text-xs md:text-6xl pt-0 float-left `};font-family:roboto;font-weight:500;
  font-size: 6rem;
  display: inline-block;
  line-height: 7px;
`
const Under = styled.span`
  ${tw`text-white`};font-family:roboto;line-height:25px;letter-spacing:0.5px;font-weight:400;border-bottom:1px solid #fdb813;
`

const AboutSub = styled.span`
  ${tw`text-white text-sm text-left md:text-justify pt-0 pl-0 lg:pt-0 lg:pl-12 md:text-md`};font-family:roboto;line-height:25px;letter-spacing:0.5px;font-weight:300;width: 100%;
`
const Line = styled.div`
  ${tw`text-white align-center w-auto m-auto text-center  pt-3  text-3xl lg:text-3xl xl:text-3xl mb-32 leading-normal
  `};font-family:roboto;clear:both;
`
const Title2 = styled.h2`
  ${tw`text-xl md:text-2xl text-white mb-2 tracking-wide relative block float-left`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);font-family:miriam libre;font-weight:500;color:#eee;clear:right;
`
const Title3 = styled.h2`
  ${tw`text-md md:text-lg text-white text-left mb-4 tracking-wide relative block float-left`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);font-family:miriam libre;font-weight:500;color:#eee;clear:right;
`

const AboutDesc = styled.p`
  ${tw`text-sm text-left md:text-justify md:text-md font-Roboto pt-0`};font-family:roboto;font-weight:300;color:#eee;letter-spacing:0.6px;line-height:25px;clear:both;
`

const ContactText = styled.div`
  ${tw`text-grey-light text-left text-xl md:text-2xl lg:text-2xl`};font-family:roboto;
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-Roboto text-md lg:text-lg`};
`
const TextAnim = styled.div`
  ${tw`align-center text-white text-3xl w-auto float-none text-center m-auto mt-4 md:mt-32`};clear:both;
  font-size: 1.875rem;font-family:roboto;font-weight:100;
  `
  const TextAnimDiv = styled.div`
  ${tw`align-center text-white text-3xl w-auto float-none flex center m-auto`};clear:both;
  margin-left: 0px;
  `

const Index = () => (
  <>
    <Layout />
    <Parallax pages={6}>
      <Hero offset={0}>
	    <Fade top delay={4700}>
      <LogoDiv><div id="logo">
      <Logo src={logo} alt="gsap" /></div>
      </LogoDiv>
      </Fade>
      <Fade top delay={4700}>
      <MenuDiv id="nume">The Amsterdam based <RedTxt>security-first</RedTxt> IT company
      </MenuDiv>
      <MenuDiv id="menu">
      <nav>
        <ul>
            <li><a href="#cybersecurity">Cybersecurity</a></li>
            <li><a href="#recovery">Recovery from hack</a></li>
            <li><a href="#it-management">IT Consulting</a></li>
            <li><a href="#pricing">Pricing</a> </li>
            <li><a href="#contact">Contact</a></li>
        </ul>
    </nav>
      </MenuDiv>
      </Fade>
      <TextAnimDiv>
      <TextAnim>
        <Fade top delay={5900}>
          <h3>In a world of ever-increasing complexity</h3>
          </Fade>
          
          <Fade id="wehelp" bottom delay={7100}>
          <Line>
          <span>we help you to keep</span>
          <TextLoop adjustingSpeed={500} delay={5000} interval={[6000]} springConfig={{ stiffness: 55, damping: 15 }}>
          <h4>cybersecurity </h4>
    <h4>IT management  </h4>
    <h4>secure hosting  </h4>
    <h4>IT support </h4>
    </TextLoop>
          <span width="87">simple</span>
          </Line>
</Fade>

        <Subtitle></Subtitle>
      </TextAnim>
      </TextAnimDiv>

    <Fade bottom delay={8300} duration={2000}> 
      <ProjectsWrapper>
          <ProjectCard
            title="Cybersecurity"
            link="https://www.behance.net/gallery/58937147/Freiheit"
            im={secimg}
            bg="transparent"
          >
            As experienced white hat hackers, whatever the scenario is, we have the knowledge to ensure that security is no longer a preoccupation for your organization. 
          </ProjectCard>
          <ProjectCard
            title="Infrastructure Management"
            link="https://www.behance.net/gallery/52915793/Harry-Potter"
            im={serverimg}
            bg="transparent"
          >
            Whether you have an existing system or you are just starting up, we assist you in deciding and building the infrastructure of tomorrow. 
          </ProjectCard>
          <ProjectCard
            title="IT support"
            link="https://www.behance.net/gallery/43907099/Tomb-Raider"
            im={webimg}
            bg="transparent"
          >
            We provide remote and on-site support for your daily IT activities; you get the comfort of in-house IT support without the extra costs associated.
          </ProjectCard> 
        </ProjectsWrapper>
        </Fade>
      </Hero>
      <About offset={1}>
        
      <Title id="cybersecurity">Cybersecurity</Title>
      <AboutDesc>
      From complete architectural review to individual advisory meetings we cover a broad spectrum when it comes to IT security.<br/> As persons involved in important hackers’ groups in our teenage years, we have a solid background and know how to protect your structure from undesirable third parties.
      <br/><br/>
      Below you can find a non-exhaustive list of given services. 
        </AboutDesc>
        <AboutHero>
          <Avatar src={infraimg} title="Hi" alt="John Doe" />
          <AboutSub>
          <RedDot>.</RedDot><Title2>IT infrastructure review</Title2><p/>
            Whether you are an SME having to deal with cybersecurity for the first time or a larger entity with already established strategies
            we assess your IT infrastructure and provide you a detailed analysis of the situation security wise. 
            <br/><br/>
          </AboutSub>
        </AboutHero>
        <AboutHero>
          <Avatar src={hackimg} title="recovery" alt="John Doe" />
          <AboutSub>
          <RedDot>.</RedDot><Title2 id="recovery">Recovery from hack</Title2><p/>
          Your company's website has been hacked? <p/>You are the victim of a ransomware?
          <p/>You have a serious slow down in your systems?<p/>
          We understand of stressful these situations can be and offer same-day remote and on-site assistance (when possible). Contact us immediatly at 020 775 17 15
          </AboutSub>
        </AboutHero>
        <AboutHero>
          <Avatar src={passimg} title="Hi" alt="John Doe" />
          <AboutSub>
          <RedDot>.</RedDot><Title2>Individual risk assessment</Title2><p/>
          Have you ever heard of data breaches? For example, In May 2016, a data breach at Linkedin exposed more than 120 million passwords, with a similar instance at Dailymotion a few months later with 90 millions accounts and their passwords revealed.        
          
          <br/><br/> We collected copies of these databases over the years and
           archived them to help you or your organization to determine on an individual account basis if any passwords has been revelead. 
           If this is the case, we can show you which ones are compromised to ensure you are secure in the future.
           <br/><br/>Today our archive combine more than 3 billions passwords coming from dozens of breaches.

          </AboutSub>
        </AboutHero>
        <AboutHero>
          <Avatar src={secureimg} title="Hi" alt="John Doe" />
          <AboutSub>
          <RedDot>.</RedDot><Title2>Secure communication</Title2><p/>
            Whether it is email communication, encrypted voip systems or more advanced multi layer encryption systems, we are always testing
            and integrating bleeding edge technologies in our projects to offer custom tailored solutions for you. 
          </AboutSub>
        </AboutHero>
        <br/><br/><br/><br/><br/><br/>
        <Title id="it-management">IT management</Title>
      <AboutDesc>
      We are specialized in IT transformation,<br/><br/>
For entities with existing structure (cloud or on-premise) we provide system management involving optimization of the existing systems, remote/on-site support (SLA available), architectural planning & integration of new systems based on your needs.
<br/><br/>Are you a small business just starting up? We provide a full service from A to Z. We analyze your needs and provide affordable integration of every system needed.
        </AboutDesc>
        <AboutHero>
          <Avatar src={serversimg} title="Hi" alt="John Doe" />
          <AboutSub>
          <RedDot>.</RedDot><Title2>Infrastructure management</Title2><p/>
          We manage all types of environments and all types of machines and operating systems, whether it is Microsoft, Linux or Apple, servers or workstations. As many management activities as possible take place from a distance, but we also arrange on-site assistance when needed.  
          </AboutSub>
        </AboutHero>
        <AboutHero>
          <Avatar src={crmimg} title="Hi" alt="John Doe" />
          <AboutSub>
          <RedDot>.</RedDot><Title2>CRM/ERP implementation</Title2><p/>
          We are specialized in open source CRM and ERP solutions. <br/><br/>
          We know how costly it can be for a small business to enter the CRM/ERP world, this is why we offer accessible integration of open source solutions that we customize depending on your expectations. 

          </AboutSub>
        </AboutHero>
        <AboutHero>
          <Avatar src={backupimg} title="Hi" alt="John Doe" />
          <AboutSub>
          <RedDot>.</RedDot><Title2>Off-site Backup</Title2><p/>
            You don't want to rely on big companies to have a copy of your company data? <br/><br/>We offer automated backup solutions and keep your encrypted data on our own backup servers located in Amsterdam.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
        </AboutDesc>
      </About>
      <Projects offset={2}>
      <Title>Pricing</Title>
      <AboutDesc id="pricing"c>
      Our hourly rates starts from 60€ depending the subject and the technical requirement associated, we always offer free consultation and quoting for new projects.  <br/><br/>
For <RedTxt>emergency situations</RedTxt> like hacked server(s) / website(s), ransomware infections, corrupted hard disk recovery or similar situations requiring diagnostic we charge a one-time fee of 180€ (ex. VAT).<br/> 
<br/>After the diagnostic is completed (generally the same day) we explain you the situation and let you know how much time is required to resolve the problem and the cost associated. You pay the final settlement only at the delivery time.
<br/>
        </AboutDesc>
        
      </Projects>
      <Contact offset={3}>
        <Inner>
      <Title id="contact">Contact</Title>
      <br/><br/>
          <Title3>Get in touch with us using the form below or contact us at 020 775 17 15 for immediate assistance</Title3>
          <br/>
          <ContactText>
          <br/><br/>
          <form
      action="https://formspree.io/pierre@gsap.nl"
      method="POST"
      className="row"
    >
      <div className="col-lg-8" style={{ margin: '0 auto' }}>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="name">Name</label>
            <input name="name" className="form-control" required />
          </div>
          <div className="form-group  col-md-6">
            <label htmlFor="_replyto">Email</label>
            <input
              type="email"
              name="_replyto"
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            className="form-control"
            name="message"
            rows="12"
            required
          />
        </div>
        <div className="text-right form-group">
          <input
            type="submit"
            value="SEND MESSAGE"
            className="btn btn-primary btn-submit"
          />
        </div>
      </div>
    </form>
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2019 by <Logo src={logo} alt="gsap" />{' '}

        </Footer>
      </Contact>
    </Parallax>
  </>
)
export default Index
