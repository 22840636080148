import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { colors } from '../../tailwind'
import Content from '../elements/Content'
import { UpDown, UpDownWide } from '../styles/animations'
import SVG from '../components/SVG'

const Wrapper = styled.div`
  ${tw`w-full xl:w-4/5`};
`

const Hero = ({ children, offset }) => (
  <>   {/*<Helmet>
  <script src={withPrefix('anime.min.js')} />
  <script src={withPrefix('linemaker.js')} />
  <script src={withPrefix('linedo.js')} />
  </Helmet>*/}
    <Content speed={0.4} offset={offset}>
      <Wrapper>{children}</Wrapper>
    </Content>
  </>
)

export default Hero

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired,
}
