import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from '../elements/Dividers'
import Content from '../elements/Content'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import Inner from '../elements/Inner'
import { UpDown, UpDownWide } from '../styles/animations'
import { colors } from '../../tailwind'
import SVG from '../components/SVG'

const Wrapper2 = styled.div`
  ${tw`w-full xl:w-4/5`};
`
const About = ({ children, offset }) => (
  <>
    <Content speed={0.4} offset={offset}>
      <Wrapper2>{children}</Wrapper2>
    </Content>
  </>
)

export default About

About.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired,
}
