import styled from 'styled-components'
import tw from 'tailwind.macro'
import { rotateAnimation } from '../styles/animations'
import triangle from '../images/triangle.svg'

export const Title = styled.h1`
  ${tw`float-left text-white tracking-wide relative block text-2xl mb-4 lg:text-3xl lg:mb-2 `};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);font-family:miriam libre;font-weight:500;color:#eee;border-bottom:2px solid #df3132;clear:both;
  margin-top: -20px;
`

export const BigTitle = styled.h1`
  ${tw`text-5xl lg:text-6xl text-white mb-6 tracking-wide`};
  text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15);
`

export const Subtitle = styled.p`
  ${tw`text-2xl lg:text-4xl font-roboto text-white mt-8 xxl:w-3/4`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`
